@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  font-family: 'Poppins', sans-serif;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer components {
  .hover-lift {
    @apply transition hover:scale-105 hover:shadow-xl;
  }
}

input {
  background-color: var(--color-secondary-50);
}

input[type='text'] {
  border: 1px solid #e5e5e5;
}

textarea {
  border: 1px solid #e5e5e5;
}

@layer base {
  body {
    @apply text-gray-900 dark:text-white;
  }
}


:root {
  --color-yellow: #F5CD46;
  --color-yellow-50: #FEFCF4;
  --color-yellow-100: #FDF7E1;
  --color-yellow-200: #FBECBA;
  --color-yellow-300: #F9E293;
  --color-yellow-400: #F7D76D;
  --color-yellow-500: #F5CD46;
  --color-yellow-600: #F2BF11;
  --color-yellow-700: #C0970A;
  --color-yellow-800: #8B6D08;
  --color-yellow-900: #564305;
  --color-yellow-950: #3B2F03;

  --color-gray-50:  #F9FAFB;
  --color-gray-100: #F3F4F6;
  --color-gray-200: #E5E7EB;
  --color-gray-300: #D1D5DB;
  --color-gray-400: #9CA3AF;
  --color-gray-500: #6B7280;
  --color-gray-600: #4B5563;
  --color-gray-700: #374151;
  --color-gray-800: #1F2937;
  --color-gray-900: #111827;

  --color-secondary: #F9FAFB;
  --color-secondary-50: #FCFCFB;
  --color-secondary-100: #F4F3EF;
  --color-secondary-200: #ECEAE3;
  --color-secondary-300: #E4E2D8;

  --color-primary: #FFFFFF;
}


.dark {
  --color-yellow: #D1A100;
  --color-yellow-50: #3B2F03;
  --color-yellow-100: #564305;
  --color-yellow-200: #8B6D08;
  --color-yellow-300: #C0970A;
  --color-yellow-400: #F2BF11;
  --color-yellow-500: #F5CD46;
  --color-yellow-600: #F7D76D;
  --color-yellow-700: #F9E293;
  --color-yellow-800: #FBECBA;
  --color-yellow-900: #FDF7E1;
  --color-yellow-950: #FEFCF4;

  --color-gray-50:  #0F172A;
  --color-gray-100: #1E293B;
  --color-gray-200: #334155;
  --color-gray-300: #475569;
  --color-gray-400: #64748B;
  --color-gray-500: #b0bed2;
  --color-gray-600: #CBD5E1;
  --color-gray-700: #E2E8F0;
  --color-gray-800: #F1F5F9;
  --color-gray-900: #F8FAFC;

  --color-secondary: #111827;
  --color-secondary-50: #152031;
  --color-secondary-100: #293546;
  --color-secondary-200: #6B7280;
  --color-secondary-300: #9CA3AF;

  --color-primary: #1F2937;
}